import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { hashHistory } from 'react-router';
import { Icon } from '../../utils';

export default class PrequalSelect extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {};
  }

  /**
   * Render
   */
  render() {
    return (
      <Row className="p-lg text-center ml-lg-n mr-lg-n mb-md-n bg-100">
        <Col sm={12} smOffset={0}>
          {(() => {
            if (this.props.session.details.isMotor) {
              return (
                <Row className="mt-md">
                  <Col sm={3} smOffset={0}>
                    <div onClick={() => { hashHistory.push('/calculators/motor-serviceability'); }} className="pt-lg pb-md mb-md card pointer card-shallow text-center">
                      <Icon glyph="directions-car" size={4} className="mute-hg" />
                      <Icon glyph="tag-more" size={3} className="secondary a" style={{ top: '44px', left: '50%', marginLeft: '2px' }} />
                      <p className="mute mt-md mb-sm-n">Motor</p>
                      <h4>Serviceability</h4>
                    </div>
                  </Col>
                  <Col sm={3} smOffset={0}>
                    <div onClick={() => { hashHistory.push('/calculators/motor-prequalification'); }} className="pt-lg pb-md mb-md card pointer card-shallow text-center">
                      <Icon glyph="directions-car" size={4} className="mute-hg" />
                      <Icon glyph="money" size={3} className="secondary a" style={{ top: '44px', left: '50%', marginLeft: '2px' }} />
                      <p className="mute mt-md mb-sm-n">Motor</p>
                      <h4>Prequalification</h4>
                    </div>
                  </Col>
                </Row>
              );
            }
          })()}
          {(() => {
            if (this.props.session.details.isResidential) {
              return (
                <Row className="mt-md">
                  <Col sm={3}>
                    <div onClick={() => { hashHistory.push('/calculators/serviceability'); }} className="pt-lg pb-md mb-md card pointer card-shallow text-center">
                      <Icon glyph="money-box" size={4} className="mute-hg" />
                      <Icon glyph="tag-more" size={3} className="primary a" style={{ top: '44px', left: '50%', marginLeft: '2px' }} />
                      <p className="mute mt-md mb-sm-n">Residential</p>
                      <h4>Serviceability</h4>
                    </div>
                  </Col>

                  <Col sm={3}>
                    <div onClick={() => { hashHistory.push('/calculators/lvr'); }} className="pt-lg pb-md card pointer card-shallow text-center">
                      <Icon glyph="map" size={4} className="mute-hg" />
                      <Icon glyph="pin" size={3} className="success a" style={{ top: '14px', left: '50%', marginLeft: '-10px' }} />
                      <p className="mute mt-md mb-sm-n">Residential</p>
                      <h4>Maximum LVR</h4>
                    </div>
                  </Col>

                  <Col sm={3}>
                    <div onClick={() => { hashHistory.push('/calculators/rate'); }} className="pt-lg pb-md card pointer card-shallow text-center">
                      <Icon glyph="receipt" size={4} className="mute-hg" />
                      <Icon glyph="money" size={3} className="secondary a" style={{ top: '44px', left: '50%', marginLeft: '2px' }} />
                      <p className="mute mt-md mb-sm-n">Residential</p>
                      <h4>Rate Quote</h4>
                    </div>
                  </Col>

                  <Col sm={3} smOffset={0}>
                    <div onClick={() => { hashHistory.push('/calculators/repayments'); }} className="pt-lg pb-md pointer card card-shallow text-center">
                      <Icon glyph="refresh" size={4} className="mute-hg" />
                      <Icon glyph="balance-wallet" size={3} className="primary a" style={{ top: '44px', left: '50%', marginLeft: '2px' }} />
                      <p className="mute mt-md mb-sm-n">Residential</p>
                      <h4>Repayments</h4>
                    </div>
                  </Col>
                </Row>
              );
            }
          })()}
          <Row className="mt-md">
            <Col sm={3}>
              <div onClick={() => { hashHistory.push('/calculators/commercial-serviceability'); }} className="pt-lg pb-md mb-md card pointer card-shallow text-center">
                <Icon glyph="city-alt" size={4} className="mute-hg" />
                <Icon glyph="tag-more" size={3} className="primary a" style={{ top: '44px', left: '50%', marginLeft: '2px' }} />
                <p className="mute mt-md mb-sm-n">Commercial</p>
                <h4>Serviceability</h4>
              </div>
            </Col>
          </Row>
          {(() => {
            if (this.props.session.details.isResidential) {
              return (
                <Row className="mt-md">
                  <Col sm={12}>
                    <h4 className="mute mt-lg pt-lg">Looking for LMI calculators?</h4>
                    <a className="btn btn-link btn-lg" rel="noopener noreferrer" href="https://helia.com.au/the-hub/calculators-estimators/lmi-fee-estimator">Helia LMI fee estimator</a>
                  </Col>
                </Row>
              );
            }
          })()}
        </Col>
      </Row>
    );
  }
}

PrequalSelect.propTypes = {
};
