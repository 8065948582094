/* eslint-disable no-shadow */
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Actions, Location } from 'history';
import { RouteHook } from 'react-router/lib/Router';
import { ResiSubmissionWebComponent } from './web-component';
import Notifications from 'react-notify-toast';

const ReactTooltip = require('react-tooltip');

import HeaderPane from '../../components/headerPane'
import PromptPane from '../../components/promptPane';
import FeedbackPane from '../../components/feedbackPane';

const opportunityAppStylesUrl = process.env.OPPORTUNITYAPP_STYLES_URL || "";

interface ResiSubmissionPageState {
  isStable: boolean;
  pendingLocation?: Location;
}

interface ResiSubmissionPageProps {
  router: any;
}

const initialState: ResiSubmissionPageState = {
  isStable: true,
};

// eslint-disable-next-line no-shadow
const routerWillLeave = (state: ResiSubmissionPageState, setState): RouteHook => (nextLocation?: Location) => {
  if (nextLocation && !state.isStable) {
    // don't allow navigation yet, wait until component is in a stable state
    // eslint-disable-next-line no-shadow
    setState((state: ResiSubmissionPageState) => ({
      ...state,
      pendingLocation: nextLocation,
    }));
    return false;
  } if (!nextLocation && !state.isStable) {
    // trying to close/navigate away from the site
    // have to return a message (cannot perform async action)
    return 'You have unsaved changes, are you sure you want to exit?';
  }

  return null; // don't do anything, allow navigation
};


export const ResiSubmissionPage = (props: RouteComponentProps<{ id?: string; }, {}> & ResiSubmissionPageProps & Location) => {
  const [state, setState] = React.useState(initialState);

  React.useEffect(() => {
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = opportunityAppStylesUrl;

    document.head.appendChild(link);

    // register leave hook
    props.router.setRouteLeaveHook(props.route, routerWillLeave(state, setState));

    // Remove the link element when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, [props.router, state]);

  const opportunityID = props.params.id || "";

  // Handle submission complete event from modal - redirect to loan tracker
  React.useEffect(() => {
    const handleSubmissionComplete = (event: Event) => {
      if (event instanceof CustomEvent) {
        const { detail: { loanId, opportunityId } } = event;
        if (loanId) {
          props.router.push(`/applications/${loanId}`);
        }
      }
    };
    window.addEventListener('submissionComplete', handleSubmissionComplete);

    return () => {
      window.removeEventListener('submissionComplete', handleSubmissionComplete);
    };
  }, [props.router]);

  // Manually override IQ styles conflicting with Spark+ styles.
  return (
    <div>
      <HeaderPane />
      <PromptPane />
      <style>
        {`
          .fade {
            opacity: 1;
          }
          .card {
            min-height: unset;
          }

          .toggle {
            border: none;
            padding: 0;
          }
        `}
      </style>
      <ResiSubmissionWebComponent
        opportunityID={opportunityID}
      />

      <Notifications />
      <FeedbackPane />
      <ReactTooltip />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  
});


export default connect(mapStateToProps)(withRouter(ResiSubmissionPage));
